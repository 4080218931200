import { store } from '@/store/store';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';

@Module({
    namespaced: true,
    name: 'adminnutricionistaModule',
    store,
    dynamic: true
})
class adminnutricionistaModule extends VuexModule {

    @Action({})
    public async getsendrecordatorio_pacientes(id: number) {
        return await ssmHttpService.get(API.AdminNutricionista + '/sendmailrecordatorio/' + id);
    }

}
export default getModule(adminnutricionistaModule);