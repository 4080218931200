

































































































import { Component, Vue } from "vue-property-decorator";
import NutricionistaModule from "@/store/modules/Admin/Nutricionista-module";
import { ClassColumnDataTable } from "@/components/DatatableGenerico/ClassColumnDataTable";
import { datatypes } from "@/components/DatatableGenerico/datatypes";
import { buttonOptions } from "@/components/Buttons/buttonOptions";
import { Nutricionista } from "@/shared/dtos/admin/Nutricionista";
import LicenciaModule from "@/store/modules/Licencia-module";
import adminnutricionistaModule from "@/store/modules/adminnutricionista-module";

import { DxDateBox } from "devextreme-vue";
import { UtilsNotify } from "@/utils/utils-notify";
@Component({
  components: {
    DataTable: () => import("@/components/DatatableGenerico/DataTable.vue"),
    AddNutricionistaForm: () =>
      import("@/views/Admin/AddNutricionistaForm.vue"),
    DxDateBox
  }
})
export default class NutricionistaLista extends Vue {
  public more_options_for: number = 0;
  public show_dialog_more_options: boolean = false;
  public show_dialog_nutricionista: boolean = false;
  public show_dialog_add_nutricionista: boolean = false;
  public nutricionista_seleccionado: Nutricionista = new Nutricionista();
  created() {
    NutricionistaModule.getNutricionistas();
  }

  get nutricionistas() {
    return NutricionistaModule.Nutricionistas.filter(x => x.id > 0);
  }

  get Columns() {
    var Columnas: ClassColumnDataTable[] = [];
    Columnas.push(
      new ClassColumnDataTable(
        "id",
        "Id nutri",
        datatypes.number,
        true,
        datatypes.maxlength
      ).ChangeWidth(100)
    );
    Columnas.push(
      new ClassColumnDataTable(
        "email",
        "Email",
        datatypes.string,
        true,
        datatypes.maxlength
      ).ChangeWidth(250)
    );
    Columnas.push(
      new ClassColumnDataTable(
        "configuracion.Nombre",
        "Nombre",
        datatypes.string,
        true,
        datatypes.maxlength
      ).ChangeWidth(150)
    );
    Columnas.push(
      new ClassColumnDataTable(
        "configuracion.Telefono1",
        "Telefono",
        datatypes.string,
        true,
        datatypes.maxlength
      ).ChangeWidth(150)
    );
    Columnas.push(
      new ClassColumnDataTable(
        "licencia.hasta",
        "Hasta",
        datatypes.date,
        true,
        datatypes.maxlength
      )
    );
        Columnas.push(
      new ClassColumnDataTable(
        "password",
        "Pwd",
        datatypes.date,
        false,
        datatypes.maxlength
      )
    );
    
    return Columnas;
  }
  public get Opciones_a_realizar() {
    let opts: buttonOptions[] = [];
    opts.push(
      new buttonOptions({
        id: 1,
        title: "Ver datos",
        EventMethod: this.VerMore
      })
    );
    opts.push(
      new buttonOptions({
        id: 1,
        title: "Send recordatorio",
        EventMethod: this.sendMailAcceso
      })
    );
    return opts;
  }

  Aceptar() {
    LicenciaModule.modificarlicencia(
      this.nutricionista_seleccionado.licencia
    ).then(() => {
      this.show_dialog_nutricionista = false;
      NutricionistaModule.getNutricionistas();
    });
  }
  VerMore() {
    this.show_dialog_nutricionista = true;
    this.show_dialog_more_options = false;
  }
  sendMailAcceso() {
    adminnutricionistaModule
      .getsendrecordatorio_pacientes(this.more_options_for)
      .then(() => UtilsNotify.NotificacionSuccess("Email Enviado", ""));
    this.show_dialog_more_options = false;
  }
  public async MoreOptions(id: any) {
    if (id <= 0) {
      return;
    }
    this.more_options_for = id;
    let ss = this.nutricionistas.find(x => x.id === id);
    if (ss !== undefined) {
      this.nutricionista_seleccionado = ss;
    }
    this.show_dialog_more_options = true;
  }
}
